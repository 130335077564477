import axios from 'axios';
import config from "../lib/config.js";


export const loginAdmin = async(payload)=>{
    console.log("admin login ",payload,config.AdminAPI)
    let data = {"data":payload}
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/adminlogin`,
            'data':data
            
        })
        console.log("returned response",resp.data)
        if(resp.data.token)
            localStorage.setItem("token",resp.data.token);

        return resp.data;
    }
    catch(err){
        console.log("err",err)
    }


}
export const getarticle=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.AdminAPI}/getarticle`,
        })
        console.log("member resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in article list",err)}
}

export const getaboutuser=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.AdminAPI}/getaboutuser`,
        })
        console.log("member resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in article list",err)}
}

export const customtokenfetch=async(data)=>{
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/tokenfetchapi`,
            'data':data
            
        })
        console.log("returned response",resp.data)
        if(resp.data.token)
            localStorage.setItem("token",resp.data.token);

        return resp.data;
    }
    catch(err){
        console.log("err",err)
    }
}

export const check  = async()=>{

    try{
    var data = {"test":"testdata"}
    var resp = await axios({
        "method":"POST",
        'url':`${config.AdminAPI}/checkroute`,
        "data":data,
        "headers":{
            "Authorization":localStorage.getItem("token")
        }
    })

    }
    catch(err){console.log("err".err)}

}


export const addlanguage = async (data) => {
 
    const resp = await axios({
        'method':'POST',
        'url':`${config.AdminAPI}/addlanguage`,
        'data':data
        
    })
    if(resp.data.token)
        localStorage.setItem("token",resp.data.token);

    return resp.data;
}


export const languagelist = async () => {
 

    var resp = await axios({
        'method':'GET',
        'url':`${config.AdminAPI}/language-list`,
})
return resp.data;

}

export const editlanguage = async (data) => {
  
    const resp = await axios({
        'method':'POST',
        'url':`${config.AdminAPI}/edit-language`,
        'data':data
        
    })
    if(resp.data.token)
        localStorage.setItem("token",resp.data.token);

    return resp.data;
}

export const localslist = async (datasss) => {
 

    var resp = await axios({
        'method':'GET',
        'url':`${config.AdminAPI}/locals-list`,
        'params':datasss
})
return resp.data;

}


export const addlocals = async (data) => {
 
    const resp = await axios({
        'method':'POST',
        'url':`${config.AdminAPI}/addlocals`,
        'data':data
        
    })
    if(resp.data.token)
        localStorage.setItem("token",resp.data.token);

    return resp.data;
}

export const editlocals = async (data) => {
  
    const resp = await axios({
        'method':'POST',
        'url':`${config.AdminAPI}/edit-locals`,
        'data':data
        
    })
    if(resp.data.token)
        localStorage.setItem("token",resp.data.token);

    return resp.data;
}



export const deletefn = async (data) => {

    const resp = await axios({
        'method':'POST',
        'url':`${config.AdminAPI}/locals-delete`,
        'data':data      
    })
    if(resp.data.token)
        localStorage.setItem("token",resp.data.token);

    return resp.data;
}