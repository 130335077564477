let key = {};
let IPFS_IMG = "https://theimpactcollective.infura-ipfs.io/ipfs/";
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
var EnvName = "demo";
var Front_market_Url = '';
var AdminAddress = '0x8f4ad1aE5AB57b2DA6DCd0Be762048bB4692C0Ce';

if (EnvName === "local") {
  Back_Url = "http://localhost:3800/v1/admin";
  Front_market_Url = "http://localhost:3800/naiftyadmin";
  var image_url = "hhttp://localhost:3800/token";
  ImG = "http://localhost:3800";
  var tradeAddress = "0x3bE52bd3A97ac6Ba20F1482e58d9694B3E15Fb05";
  var singleAddress = "0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1";
  var multipeAddress = "0x4958A36d8d71abd35D5434EF78023B3284D93e63";
  var networkVersion = "43113";
  var chainId = "0xa86a";
  BNBProvider = "https://api.avax-test.network/ext/bc/C/rpc";
  var Back_Url_Token = "http://localhost:3800/v1";
}

if (EnvName === "stage") {
  Back_Url = "https://apistaging.theimpactcollective.com/admin";
  Front_market_Url = "http://localhost:3000";
  var image_url = "https://apistaging.theimpactcollective.com/token";
  ImG = "https://apistaging.theimpactcollective.com";
  var tradeAddress = "0x16D9f2EA5Ab7c93Ef3aB2c7E983CfC12FB03b91c";
  var singleAddress = "0x26a40070a000f717A32d3e6AAe9A8f26552fB662";
  var multipeAddress = "0xae4d5a74432e6e0702bc61d7e537161ff5d740ac";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "https://apistaging.theimpactcollective.com/v1";
}

if (EnvName === "demo") {
  Back_Url = "https://joyfunnftdemoapi.maticz.in/v1/admin";
  Front_market_Url = "https://joyfunnftdemo.maticz.in/naiftyadmin";
  var image_url = "https://joyfunnftdemoapi.maticz.in/token";
  ImG = "https://joyfunnftdemoapi.maticz.in";
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1";
  var multipeAddress = "0x4958A36d8d71abd35D5434EF78023B3284D93e63";
  var networkVersion = "43113";
  var chainId = "0xa86a";
  BNBProvider = "https://api.avax-test.network/ext/bc/C/rpc";
  var Back_Url_Token = "joyfunnftdemoapi.maticz.in/v1";
}

if (EnvName === "production") {
  FRONT_URL = "https://ditmax.market";
  Front_market_Url = "https://ditmax.market";
  Back_Url = "https://api.ditmax.market/v1/admin";
  var image_url = "https://api.ditmax.market/token";
  ImG = "https://api.ditmax.market";
  var tradeAddress = "0x3b24d9b2d1ad22108b0f7b24a576b1c412ae016d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "1";
  var chainId = "0x1";
  BNBProvider = "https://mainnet.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2";
  var Back_Url_Token = "ttps://api.ditmax.market/v1";
}

key = {
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBProvider,
  tradeAddress: tradeAddress,
  singleAddress: singleAddress,
  multipeAddress: multipeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  AdminAddress: "0x8f4ad1aE5AB57b2DA6DCd0Be762048bB4692C0Ce",
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url:Front_market_Url,
  networkVersion:networkVersion
};

export default key;
